import { useContext, useEffect, useState } from "react";
import DesktopLayout from "../../../layout/DesktopLayout";
import MobileLayout from "../../../layout/MobileLayout";
import breakpoints from "../../../theme/antdesign";
import HeaderDocument from "./HeaderDocument";
import useScreenSize from "../../../hooks/useScreensize";
import Context from "../../../context/Context";
import { getReportById } from "../../../api/api";
import { useParams } from "react-router-dom";
import { ThianaAssistantMode, emptyReport } from "../../../types/Reports";
import { Report as ThianaReport } from "@thiana/api-thiana-client";
import { Flex, Tour, TourStepProps, Typography, message } from "antd";
import { DefaultEventsMap } from "socket.io/dist/typed-events";
import { Socket } from "socket.io-client";
import { Template } from "../../../types/Templates";
import useProfessionals from "../../../hooks/useProfessionals";
interface Props {
  reports: ThianaReport[];
  setReports: React.Dispatch<React.SetStateAction<ThianaReport[]>>;
  SOCKET_ASR: React.MutableRefObject<
    Socket<DefaultEventsMap, DefaultEventsMap> | undefined
  >;
  templates: Template[];
  setTemplates: React.Dispatch<React.SetStateAction<Template[]>>;
  mode: ThianaAssistantMode;
  steps: TourStepProps[];
}

export default function Document(props: Props) {
  const {
    isMenuHidden,
    dispatchFlow,
    updateIsMenuHidden,
    updateIsMicrophoneAvailable,
    openTour,
    professional,
    currentReport,
    updateCurrentReport,
    updateOpenTour,
  } = useContext(Context);
  const { id } = useParams();
  const { currentScreenSize } = useScreenSize();
  const [chosenTemplateContent, setChosenTemplateContent] = useState<string>();
  const [messageApi, contextHolder] = message.useMessage();

  // Fetch report by id when :id in URL changes
  const fetchReportByID = async () => {
    if (id) {
      let reportsResponse: any; //TODO - Remettre le type avec le client api-thiana-client
      const response = await getReportById(id);
      if (response.status === 200) {
        reportsResponse = await response.json();
        updateCurrentReport(reportsResponse.data[0]);
      }
    }
  };

  useEffect(() => {
    // Vérifier si la permission d'accéder au microphone a été accordée
    (async () => {
      await navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then(
          (stream) => {
            updateIsMicrophoneAvailable(true);
          },
          (e) => {
            updateIsMicrophoneAvailable(true);
            messageApi.open({
              type: "warning",
              content:
                "Vous n'avez pas autorisé l'accès au microphone. Veuillez le faire dans les paramètres de votre navigateur.",
            });
          }
        );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchReportByID();
    if (id) dispatchFlow({ type: "SWITCH_REPORT" });
    else {
      if (currentReport)
        updateCurrentReport({
          ...emptyReport,
          report_type: currentReport?.report_type,
        });
      else updateCurrentReport(emptyReport);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    setIsVisible(true);
  }, []);

  const { patchProfessional } = useProfessionals();

  useEffect(() => {
    (async () => {
      if (
        professional &&
        professional.is_assistant_tutorial_displayed === false
      ) {
        updateOpenTour(true);
        // On met à jour le professionel
        await patchProfessional(professional.id, {
          is_assistant_tutorial_displayed: true,
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [professional]);

  return (
    <div
      className={isVisible ? "slide-in active" : "slide-in"}
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        height: `calc(100vh - 20px)`,
        zIndex: 999,
        gap: 10,
      }}
      onClick={() =>
        currentScreenSize < breakpoints.sm && !isMenuHidden
          ? updateIsMenuHidden(true)
          : null
      }
    >
      <Tour
        open={openTour}
        onClose={() => updateOpenTour(false)}
        onFinish={() => console.log("Tour finished")}
        steps={props.steps}
        disabledInteraction={true}
      />
      {contextHolder}
      <HeaderDocument
        SOCKET_ASR={props.SOCKET_ASR}
        reports={props.reports}
        setReports={props.setReports}
      />

      <Flex vertical style={{ flex: 1 }}>
        <div style={{ display: "flex", flex: 1 }}>
          {currentScreenSize < breakpoints.sm ? (
            <MobileLayout
              SOCKET_ASR={props.SOCKET_ASR}
              reports={props.reports}
              setReports={props.setReports}
              templates={props.templates}
              setTemplates={props.setTemplates}
              chosenTemplateContent={chosenTemplateContent}
              setChosenTemplateContent={setChosenTemplateContent}
              mode={props.mode}
            />
          ) : (
            <DesktopLayout
              SOCKET_ASR={props.SOCKET_ASR}
              reports={props.reports}
              setReports={props.setReports}
              templates={props.templates}
              setTemplates={props.setTemplates}
              chosenTemplateContent={chosenTemplateContent}
              setChosenTemplateContent={setChosenTemplateContent}
              mode={props.mode}
            />
          )}
        </div>
      </Flex>

      <Typography
        style={{
          textAlign: "center",
          fontSize: 10,
          color: "grey",
        }}
      >
        Thiana utilise un modèle d'IA génératif. Par conséquent, les
        observations médicales générées peuvent comporter des erreurs ou être
        incomplètes. Veillez à toujours vérifier le contenu de l'observation
        avant de vous en servir.
      </Typography>
    </div>
  );
}
