import { useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getMe, postAskNewTokenByScope } from "../../../api/api";
import Context from "../../../context/Context";
import { GetMeResponse } from "../../../types/Users";

export default function AuthController() {
  let navigate = useNavigate();
  let { accessTokenFromURL } = useParams();
  const { updateUser, updateProfessional, updateSubscription } =
    useContext(Context);

  useEffect(() => {
    if (accessTokenFromURL) tryToConnectWithTokensFromURL();
    else tryToConnectWithTokensFromStorage();
  });

  const tryToConnectWithTokensFromURL = async () => {
    if (accessTokenFromURL) {
      let response = await postAskNewTokenByScope(
        { scope: 1 },
        accessTokenFromURL
      );
      if (response.status === 200) {
        navigate("/documents");
      }
    }
  };

  const tryToConnectWithTokensFromStorage = async () => {
    const accessToken = localStorage.getItem("accessJWT");
    const refreshToken = localStorage.getItem("refreshJWT");
    if (accessToken && refreshToken) {
      let response = await getMe(); // les tokens du storage seront utilisé dans le get() du getMe(),  et le middleware mettra à jour les tokens
      if (response.status === 200) {
        let getMeResponse: GetMeResponse;
        navigate("/documents");
        getMeResponse = await response.json();
        updateUser(getMeResponse.data[0].user);
        updateProfessional(getMeResponse.data[0].professional);
        updateSubscription(getMeResponse.data[0].subscription);
      }
    } else navigate("/logout");
  };

  return <></>;
}
