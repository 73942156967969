import { useState, useRef, useContext, useEffect } from "react";
import io, { Socket } from "socket.io-client";
import {
  IMediaRecorder,
  MediaRecorder,
  register,
} from "extendable-media-recorder";
import { connect } from "extendable-media-recorder-wav-encoder";
import Context from "../context/Context";
import useLLM from "./useLLM";
import { useParams } from "react-router-dom";
import useReports from "./useReports";
import { DefaultEventsMap } from "@socket.io/component-emitter";
import { Report as ThianaReport } from "@thiana/api-thiana-client";
import { isChrome, isFirefox, isSafari } from "react-device-detect";

let chunks: BlobPart[] = [];

const URL_SBM = process.env.REACT_APP_URL_BACKIA as string;

(async () => {
  await register(await connect());
})();

const getNavigator = () => {
  if (isFirefox) return "firefox";
  else if (isSafari) return "safari";
  else if (isChrome) return "chrome";
  //@ts-ignore
  else if (navigator.brave) return "brave";
  else return "unknow";
};

interface Props {
  SOCKET_ASR: React.MutableRefObject<
    Socket<DefaultEventsMap, DefaultEventsMap> | undefined
  >;

  reports: ThianaReport[];
  setReports: React.Dispatch<React.SetStateAction<ThianaReport[]>>;
}

export default function useASR(props: Props) {
  const [mediaStream, setMediaStream] = useState<MediaStream>();
  const isRecordingRef = useRef(false);
  const [mediaRecorder, setMediaRecorder] = useState<IMediaRecorder>();
  const [liveResponse, setLiveResponse] = useState("");
  const liveResponseRef = useRef("");
  const liveResponseHistory = useRef<string[]>(["", ""]);
  const [animatedLiveResponse, setAnimatedLiveResponse] = useState("");
  const [fixedLiveResponse, setFixedLiveResponse] = useState("");
  const [validatedIndex, setValidatedIndex] = useState(0);
  const [inValidationIndex, setInValidationIndex] = useState(0);
  const nextRunLLM = useRef(false);

  const { launchLLM } = useLLM({
    reports: props.reports,
    setReports: props.setReports,
  });
  // Context
  const {
    updateIsASRProcessing,
    dispatchFlow,
    currentReport,
    updateCurrentReport,
    mode,
  } = useContext(Context);

  let { id } = useParams();
  const { updateReport, createReport } = useReports({
    reports: props.reports,
    setReports: props.setReports,
  });

  useEffect(() => {
    if (nextRunLLM.current && currentReport) {
      // On met à jour le flow pour dire que la prochaine étape c'est le lancement du LLM
      dispatchFlow({
        type: "SOCKET_LAUNCH_LLM",
      });
      launchLLM(currentReport.transcription);
      nextRunLLM.current = false;
    }
    // Sinon, la prochaine étape est le stop de l'ASR
    else
      dispatchFlow({
        type: "SOCKET_ASR_STOP",
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReport?.transcription]);

  const startRecording = async () => {
    console.log("startRecording");
    isRecordingRef.current = true;
    updateIsASRProcessing(true);
    const accessToken = localStorage.getItem("accessJWT");

    try {
      // On connecte la websocket entre le front et le back ia
      props.SOCKET_ASR.current = io(URL_SBM, {
        auth: {
          token: accessToken,
        },
      });
      // Connect error
      props.SOCKET_ASR.current.on("connect_error", (error) => {
        console.error(error);
      });

      // on connect websocket
      props.SOCKET_ASR.current.on("connect", () => {
        console.log("TRANSCRIPTION : Connected to websocket.");
        console.log(getNavigator());
        props.SOCKET_ASR.current?.emit("informations", {
          mode: "dictation",
          navigator: getNavigator(),
        });
      });

      // live_feedback
      props.SOCKET_ASR.current.on("live_feedback", (response: any) => {
        setInValidationIndex(response.in_validation);
        setValidatedIndex(response.validated);
        liveResponseRef.current = response.text;
        liveResponseHistory.current.shift();
        liveResponseHistory.current.push(response.text.substring(1));
        // set animated live response : slice du retour de la socket
        let animatedText = response.text.slice(
          liveResponseHistory.current[0].length,
          liveResponseHistory.current[1].length
        );
        // set fixed live response
        let fixedText = response.text.slice(
          0,
          liveResponseHistory.current[0].length
        );
        dispatchFlow({
          type: "SOCKET_ASR_FEEDBACK",
          payload: {
            fixedLiveResponse: fixedText,
            animatedLiveResponse: animatedText,
            validationIndices: {
              inValidationIndex: response.in_validation,
              validatedIndex: response.validated,
            },
          },
        });
        setAnimatedLiveResponse(animatedText);
        setFixedLiveResponse(fixedText);
      });

      // final_response
      props.SOCKET_ASR.current.on("final_response", async () => {
        // On déclare le report mis à jour
        let updatedReport: any = {
          ...currentReport,
          transcription: currentReport?.transcription + liveResponseRef.current,
        };
        // Si aucun report.id n'est dans l'url, c'est qu'il faut créer un nouveau report
        if (!id) {
          let reportCreated = await createReport({
            transcription: liveResponseRef.current,
            generation: currentReport?.generation,
            title: currentReport?.title,
            report_type: mode,
          });
          // Si le report a bien été crée
          if (reportCreated) {
            // On envoie l'id de report crée au back IA
            props.SOCKET_ASR.current?.emit("send_report_id", {
              report_id: reportCreated.id,
            });
            updatedReport = reportCreated;
            // Si on veut lancer le LLM directement :

            // On met à jour le currentReport avec la suite transcription reçu
            updateCurrentReport(updatedReport);
          }
        }

        // Sinon, on dispatch juste SOCKET_ASR_STOP avec la dernière réponse
        else {
          props.SOCKET_ASR.current?.emit("send_report_id", {
            report_id: id,
          });

          if (nextRunLLM.current) {
            // On met à jour le flow pour dire que la prochaine étape c'est le lancement du LLM
            dispatchFlow({
              type: "SOCKET_LAUNCH_LLM",
            });
            launchLLM(updatedReport.transcription);
          }
          // Sinon, la prochaine étape est le stop de l'ASR
          else
            dispatchFlow({
              type: "SOCKET_ASR_STOP",
            });

          // On met à jour le currentReport avec la suite transcription reçu
          updateCurrentReport(updatedReport);
          // On met à jour le report en bdd avec la transcription reçu
          updateReport(updatedReport);
        }
        setValidatedIndex(0);
        setInValidationIndex(0);
        updateIsASRProcessing(false);
        isRecordingRef.current = false;
        liveResponseRef.current = "";
        setLiveResponse("");
      });

      props.SOCKET_ASR.current.on("record_done", async () => {
        try {
          props.SOCKET_ASR.current?.disconnect();
          console.log("TRANSCRIPTION : Disconnected from websocket.");
        } catch (error) {
          console.error(error);
        }
      });

      // On démarre l'enregistrement de l'audio et on récupère les données audios dans reader.result
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });

        // On crée un context
        const audioContext = new AudioContext(
          isFirefox || isSafari ? undefined : { sampleRate: 16000 }
        );

        // On crée un sourceNode
        const mediaStreamAudioSourceNode = new MediaStreamAudioSourceNode(
          audioContext,
          { mediaStream: stream }
        );

        // Nouvelle destination avec l'audio context
        const mediaStreamAudioDestinationNode =
          new MediaStreamAudioDestinationNode(
            audioContext,
            isFirefox || isSafari
              ? undefined
              : {
                  channelCount: 1,
                  channelCountMode: "explicit",
                  channelInterpretation: "speakers",
                }
          );

        mediaStreamAudioSourceNode.connect(mediaStreamAudioDestinationNode);

        // On crée un mediaRecorder en wav
        const mediaRecorder = new MediaRecorder(
          mediaStreamAudioDestinationNode.stream,
          {
            mimeType: "audio/wav",
            audioBitsPerSecond: isFirefox || isSafari ? 768000 : 256000,
          }
        );
        setMediaStream(stream);
        setMediaRecorder(mediaRecorder);

        mediaRecorder.addEventListener("dataavailable", (event) => {
          chunks.push(event.data);
          var reader = new FileReader();
          var blob = event.data;
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            if (isRecordingRef.current)
              props.SOCKET_ASR.current?.emit("command", {
                audio: reader.result,
                channelCount:
                  isFirefox || isSafari
                    ? 2
                    : mediaStreamAudioDestinationNode.channelCount,
                sampleRate: audioContext.sampleRate,
              });
          };
        });
        mediaRecorder.start(500);
        isRecordingRef.current = true;
        // });
      } catch (error) {
        console.error(error);
      }
    } catch (error) {
      // Erreur websocket
      console.error(error);
    }
  };

  // Cette fonction met fin à l'enregistrement de micro, et coupe la websocket en envoyant un évènement 'finalize' (le back ia réponse alors sur le 'final_response')
  const stopRecording = async (toLLM = false) => {
    console.log("stopRecording");
    if (toLLM === true) nextRunLLM.current = true;
    mediaRecorder?.stop();
    mediaStream?.getTracks().forEach((track) => track.stop());
    props.SOCKET_ASR.current?.emit("finalize", { end: true });
  };

  return {
    startRecording,
    stopRecording,
    liveResponse,
    isRecordingRef,
    animatedLiveResponse,
    fixedLiveResponse,
    validatedIndex,
    inValidationIndex,
  };
}
