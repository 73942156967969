import { Navigate } from "react-router-dom";
import { hasValidToken } from "../utils/security";
import { useContext, useEffect } from "react";
import Context from "../context/Context";
import { getFileFromS3, getMe } from "../api/api";
import { GetMeResponse } from "../types/Users";
import { EMPTY_UUID } from "../utils/constants";

const Protected = (props: any) => {
  const {
    updateUser,
    updateProfessional,
    updateSubscription,
    updateFileAvatar,
  } = useContext(Context);

  // useEffect to get user informations
  useEffect(() => {
    (async () => {
      let response = await getMe(); // les tokens du storage seront utilisé dans getMe(), et le middleware mettra à jour les tokens
      if (response.status === 200) {
        let getMeResponse: GetMeResponse;
        let getFileResponse: any;
        getMeResponse = await response.json();
        updateUser(getMeResponse.data[0].user);
        updateProfessional(getMeResponse.data[0].professional);
        updateSubscription(getMeResponse.data[0].subscription);
        // fetch 2 : Si il y a un avatar, on le récupère
        if (getMeResponse.data[0].professional.avatar !== EMPTY_UUID) {
          let response2 = await getFileFromS3(
            getMeResponse.data[0].professional.avatar
          );
          if (response2.status === 200) {
            getFileResponse = await response2.blob();
            updateFileAvatar(URL.createObjectURL(getFileResponse));
          }
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!hasValidToken()) {
    return <Navigate to="/" replace />;
  }
  return props.children;
};
export default Protected;
