import React, { useContext, useRef, useState } from "react";
import { io } from "socket.io-client";
import Context from "../context/Context";
import useReports from "./useReports";
import { Report as ThianaReport } from "@thiana/api-thiana-client";

const URL_SBM = process.env.REACT_APP_URL_BACKIA as string;

interface Props {
  reports: ThianaReport[];
  setReports: React.Dispatch<React.SetStateAction<ThianaReport[]>>;
  chosenTemplateContent?: string;
}

export default function useLLM(props: Props) {
  const isLLMProcessingRef = useRef(false);
  const socketRef = useRef<any>();
  const summaryHistory = useRef<string[]>(["", ""]);
  const [animatedLLMResponseState, setAnimatedLLMResponseState] = useState("");
  const [fixedLLMResponseState, setFixedLLMResponseState] = useState("");
  const globalSummaryRef = useRef<string>("");
  const {
    updateIsLLMProcessing,
    dispatchFlow,
    updateExtractionStep,
    mode,
    currentReport,
    updateCurrentReport,
  } = useContext(Context);
  let { updateReport, createSnapshot } = useReports({
    reports: props.reports,
    setReports: props.setReports,
  });
  const extractionStep = useRef(0);

  const extractionsStepStrings = [
    "Extraction des traitements... (2/8)",
    "Extraction des antécédents... (3/8)",
    "Extraction des allergies... (4/8)",
    "Extraction du mode de vie... (5/8)",
    "Extraction de l'examen physique... (6/8)",
    "Extraction de la prise en charge... (7/8)",
    "Structuration de l'observation... (8/8)",
  ];

  const connectWebsocketAndSendTranscription = async (
    transcription: string | undefined
  ) => {
    if (currentReport) {
      const accessToken = localStorage.getItem("accessJWT");
      try {
        socketRef.current = io(URL_SBM, {
          auth: {
            token: accessToken,
          },
        });
        // on connect
        socketRef.current.on("connect", () => {
          console.log("Connected to websocket.");
          updateExtractionStep("Extraction de l'anamnèse... (1/8)");
          socketRef.current.emit("summarize", {
            transcription: transcription,
            type: mode,
            template: mode === "statement" ? props.chosenTemplateContent : null,
            report_id: currentReport.id,
          });
        });
        //Listen
        socketRef.current.on("summary_callback", async (response: any) => {
          // On met à jour le currentReport affiché
          let updatedReport = {
            ...currentReport,
            generation: response.summary,
          };
          updateCurrentReport(updatedReport);
          // Si le back IA n'a terminé pas, on incrémente les steps
          if (!response.finished) {
            updateExtractionStep(
              extractionsStepStrings[extractionStep.current]
            );
            extractionStep.current = extractionStep.current + 1;
          }
          // Si le back IA a terminé, on met à jour le report en bdd avec la generation reçu
          else {
            extractionStep.current = 0;
            createSnapshot(updatedReport); // On crée un snapshot en bdd du report actuel
            updateReport(updatedReport); // On met à jour le report en bdd
          }
          dispatchFlow({
            type: "SOCKET_LLM_FEEDBACK",
          });
          globalSummaryRef.current = response.summary;
          summaryHistory.current.push(response.summary);
          summaryHistory.current.shift();

          //set animated live response : slice du retour de la socket
          let animatedText = response.summary.slice(
            summaryHistory.current[0].length,
            summaryHistory.current[1].length
          );
          //set fixed live response
          let fixedText = response.summary.slice(
            0,
            summaryHistory.current[0].length
          );
          setAnimatedLLMResponseState(animatedText);
          setFixedLLMResponseState(fixedText);

          if (response.finished === true) {
            globalSummaryRef.current = response.summary;
            socketRef.current.disconnect();
            updateIsLLMProcessing(false);
            dispatchFlow({
              type: "SOCKET_LLM_STOP",
              payload: response.summary,
            });
          }
        });
      } catch (error) {
        // Erreur websocket
        console.error(error);
      }
    }
  };

  const launchLLM = (transcription: string | undefined) => {
    updateIsLLMProcessing(true);
    connectWebsocketAndSendTranscription(transcription);
  };

  const abortLLM = async () => {
    await socketRef.current.emit("stop_generation");
    socketRef.current.disconnect();
    updateIsLLMProcessing(false);
  };

  return {
    launchLLM,
    abortLLM,
    isLLMProcessingRef,
    fixedLLMResponseState,
    animatedLLMResponseState,
    globalSummaryRef,
  };
}
