import { useContext } from "react";
import { AudioOutlined } from "@ant-design/icons";
import { Segmented } from "antd";
import { SegmentedValue } from "rc-segmented";
import TranscriptionView from "../views/TranscriptionView";
import GenerationView from "../views/GenerationView";
import ControlCommands from "../components/protected/commands/ControlCommands";
import Context from "../context/Context";
import { Report as ThianaReport } from "@thiana/api-thiana-client";
import { DefaultEventsMap } from "socket.io/dist/typed-events";
import { Socket } from "socket.io-client";
import { Template } from "../types/Templates";
import { ThianaAssistantMode } from "../types/Reports";
interface Props {
  SOCKET_ASR: React.MutableRefObject<
    Socket<DefaultEventsMap, DefaultEventsMap> | undefined
  >;
  reports: ThianaReport[];
  setReports: React.Dispatch<React.SetStateAction<ThianaReport[]>>;
  templates: Template[];
  setTemplates: React.Dispatch<React.SetStateAction<Template[]>>;
  chosenTemplateContent: string | undefined;
  setChosenTemplateContent: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  mode: ThianaAssistantMode;
}
export default function MobileLayout(props: Props) {
  const { currentTab, updateCurrentTab } = useContext(Context);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        gap: 8,
      }}
    >
      <Segmented
        block
        style={{ width: "100%" }}
        size="large"
        onChange={(selectedTab: SegmentedValue) =>
          updateCurrentTab(selectedTab.toString())
        }
        value={currentTab}
        options={[
          {
            label: "Transcription",
            value: "transcription",
            icon: <AudioOutlined />,
          },
          {
            label: "Observation",
            value: "generation",
            icon: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-stars"
                viewBox="0 0 16 16"
              >
                <path d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828l.645-1.937zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.734 1.734 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.734 1.734 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.734 1.734 0 0 0 3.407 2.31l.387-1.162zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L10.863.1z" />
              </svg>
            ),
          },
        ]}
      />
      {currentTab === "transcription" ? (
        <TranscriptionView
          reports={props.reports}
          setReports={props.setReports}
        />
      ) : (
        <GenerationView
          reports={props.reports}
          setReports={props.setReports}
          templates={props.templates}
          setTemplates={props.setTemplates}
          chosenTemplateContent={props.chosenTemplateContent}
          setChosenTemplateContent={props.setChosenTemplateContent}
        />
      )}
      <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
        <ControlCommands
          SOCKET_ASR={props.SOCKET_ASR}
          reports={props.reports}
          setReports={props.setReports}
          chosenTemplateContent={props.chosenTemplateContent}
          mode={props.mode}
          templates={props.templates}
        />
      </div>
    </div>
  );
}
