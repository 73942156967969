import GenerationView from "../views/GenerationView";
import TranscriptionView from "../views/TranscriptionView";
import ControlCommands from "../components/protected/commands/ControlCommands";
import { DefaultEventsMap } from "socket.io/dist/typed-events";
import { Socket } from "socket.io-client";
import { Template } from "../types/Templates";
import { Report as ThianaReport } from "@thiana/api-thiana-client";
import { ThianaAssistantMode } from "../types/Reports";

interface Props {
  SOCKET_ASR: React.MutableRefObject<
    Socket<DefaultEventsMap, DefaultEventsMap> | undefined
  >;

  reports: ThianaReport[];
  setReports: React.Dispatch<React.SetStateAction<ThianaReport[]>>;
  templates: Template[];
  setTemplates: React.Dispatch<React.SetStateAction<Template[]>>;
  chosenTemplateContent: string | undefined;
  setChosenTemplateContent: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  mode: ThianaAssistantMode;
}
export default function DesktopLayout(props: Props) {
  return (
    <div style={{ display: "flex", flexDirection: "column", flex: 1, gap: 8 }}>
      <div style={{ display: "flex", flexDirection: "row", flex: 1, gap: 8 }}>
        <div style={{ display: "flex", flex: 1 }}>
          <TranscriptionView
            reports={props.reports}
            setReports={props.setReports}
          />
        </div>
        <div style={{ display: "flex", flex: 1 }}>
          <GenerationView
            reports={props.reports}
            setReports={props.setReports}
            templates={props.templates}
            setTemplates={props.setTemplates}
            chosenTemplateContent={props.chosenTemplateContent}
            setChosenTemplateContent={props.setChosenTemplateContent}
          />
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
        <ControlCommands
          SOCKET_ASR={props.SOCKET_ASR}
          reports={props.reports}
          setReports={props.setReports}
          chosenTemplateContent={props.chosenTemplateContent}
          mode={props.mode}
          templates={props.templates}
        />
      </div>
    </div>
  );
}
