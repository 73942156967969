import {
  FileTextOutlined,
  GlobalOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Button, Flex, Segmented } from "antd";
import { SegmentedValue } from "antd/es/segmented";
import { useContext, useEffect, useState } from "react";
import useScreenSize from "../../../hooks/useScreensize";
import breakpoints from "../../../theme/antdesign";
import Context from "../../../context/Context";
import { Template, TemplatesResponse } from "../../../types/Templates";
import MyTemplates from "./myTemplates/MyTemplates";
import { getTemplates } from "../../../api/api";
import { ThianaPagination } from "../../../types/Pagination";
interface Props {
  templates: Template[];
  setTemplates: React.Dispatch<React.SetStateAction<Template[]>>;
}

export default function Templates(props: Props) {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    setIsVisible(true);
  }, []);

  const { currentScreenSize } = useScreenSize();
  const [currentTab, setcurrentTab] = useState<string>("templates");
  const { isMenuHidden, updateIsMenuHidden } = useContext(Context);
  const [templatesPagination, setTemplatesPagination] =
    useState<ThianaPagination>({
      items: 50,
      currentPage: 1,
      nbPages: 1,
    });

  // Ce useEffect fetch les templates
  useEffect(() => {
    (async () => {
      let response = await getTemplates(
        templatesPagination.items,
        templatesPagination.currentPage
      );
      if (response.status === 200) {
        let templatesResponse: TemplatesResponse;
        templatesResponse = await response.json();
        if (templatesResponse?.data !== null) {
          if (templatesPagination.currentPage === 1) {
            props.setTemplates(templatesResponse.data);
            if (templatesResponse.metadata) {
              setTemplatesPagination({
                ...templatesPagination,
                nbPages: templatesResponse.metadata.pages,
              });
            }
          } else
            props.setTemplates(
              [...props.templates].concat(templatesResponse.data)
            );
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templatesPagination.currentPage]);

  return (
    <Flex
      className={isVisible ? "slide-in active" : "slide-in"}
      vertical
      gap={"small"}
      style={{
        height: "calc(100vh - 10px)",
        maxHeight: "calc(100vh - 10px)",
      }}
    >
      <Flex gap={"small"} align="center">
        {isMenuHidden ? (
          <Button
            type={currentScreenSize < breakpoints.sm ? "default" : "text"}
            size={currentScreenSize < breakpoints.sm ? "middle" : "large"}
            icon={<UnorderedListOutlined />}
            onClick={() => updateIsMenuHidden(false)}
          />
        ) : null}

        <Breadcrumb
          items={[
            {
              title: "Thiana Assistant",
            },
            {
              title: "Modèles",
            },
          ]}
        ></Breadcrumb>
      </Flex>

      <Segmented
        onChange={(value: SegmentedValue) => setcurrentTab(value.toString())}
        value={currentTab}
        options={options}
      />

      {currentTab ? (
        <MyTemplates
          templatesPagination={templatesPagination}
          setTemplatesPagination={setTemplatesPagination}
          templates={props.templates}
          setTemplates={props.setTemplates}
        />
      ) : null}
    </Flex>
  );
}

const options = [
  {
    label: "Mes modèles",
    value: "templates",
    icon: <FileTextOutlined />,
  },
  {
    label: "Communauté",
    value: "community",
    icon: <GlobalOutlined />,
    disabled: true,
  },
];
