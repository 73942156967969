import { Button, Flex, Switch, Tag, Tooltip, Typography, theme } from "antd";
import {
  PlusOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";
import LOGO from "../../../assets/thiana_full.png";
import useScreenSize from "../../../hooks/useScreensize";
import breakpoints from "../../../theme/antdesign";
import { useContext, useEffect, useState } from "react";
import Context from "../../../context/Context";
import useReports from "../../../hooks/useReports";
import useASR from "../../../hooks/useASR";
import { Socket } from "socket.io-client";
import { DefaultEventsMap } from "socket.io/dist/typed-events";
import { Report as ThianaReport } from "@thiana/api-thiana-client";
import { useParams } from "react-router-dom";

interface Props {
  SOCKET_ASR: React.MutableRefObject<
    Socket<DefaultEventsMap, DefaultEventsMap> | undefined
  >;

  reports: ThianaReport[];
  setReports: React.Dispatch<React.SetStateAction<ThianaReport[]>>;
}

export default function HeaderDocument(props: Props) {
  const { useToken } = theme;
  const { currentScreenSize } = useScreenSize();
  const { resetAppState } = useReports({
    reports: props.reports,
    setReports: props.setReports,
  });
  const [lastMode, setLastMode] = useState<"conversation" | "statement">(
    "conversation"
  );
  const { stopRecording } = useASR({
    SOCKET_ASR: props.SOCKET_ASR,
    reports: props.reports,
    setReports: props.setReports,
  });
  const {
    isMenuHidden,
    updateIsMenuHidden,
    isASRProcessing,
    stepsRef,
    mode,
    currentReport,
    updateMode,
  } = useContext(Context);
  const { token } = useToken();
  let { id } = useParams();
  // useReports hook
  const { updateReport } = useReports({
    reports: props.reports,
    setReports: props.setReports,
  });

  useEffect(() => {
    if (currentReport) {
      if (currentReport.report_type === "statement") updateMode("statement");
      else if (currentReport.report_type === "conversation")
        updateMode("conversation");
      else if (currentReport.report_type === "none") updateMode(lastMode);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReport?.report_type]);

  // Checked true = "conversation", checked false = "statement"
  const onChangeMode = async (checked: boolean) => {
    // Si on a un report en cours, on le met à jour
    if (id && currentReport)
      await updateReport({
        ...currentReport,
        report_type: checked ? "conversation" : "statement",
      });
    // Sinon, on change le mode
    else {
      if (mode === "statement") {
        updateMode("conversation");
        setLastMode("conversation");
      } else {
        updateMode("statement");
        setLastMode("statement");
      }
    }
  };

  const isSwitchChecked = (): boolean => {
    // Si il y a un report en cours, on regarde le currentReport.report_type
    if (id && currentReport) {
      if (
        currentReport.report_type === "conversation" ||
        currentReport.report_type === "none"
      )
        return true;
      return false;
    } else {
      if (mode === "conversation") return true;
      return false;
    }
  };

  return (
    <Flex align="center">
      <Flex align="center" gap="small" style={{ flex: 1 }}>
        <div>
          {isMenuHidden ? (
            <Flex align="center" gap={"small"}>
              <Button
                icon={<UnorderedListOutlined />}
                onClick={() => updateIsMenuHidden(false)}
              />
              <Button
                icon={<PlusOutlined />}
                onClick={() => {
                  if (isASRProcessing) stopRecording();
                  resetAppState();
                }}
              >
                {currentScreenSize > breakpoints.md ? "Nouveau document" : null}
              </Button>
            </Flex>
          ) : null}
        </div>
      </Flex>

      <Flex
        vertical
        align={currentScreenSize > breakpoints.md ? "center" : "end"}
        style={{ flex: currentScreenSize > breakpoints.md ? 1 : 3 }}
        ref={stepsRef[2]}
      >
        <Flex
          align="center"
          gap={"small"}
          style={{
            flex: 1,
          }}
        >
          <Tooltip
            title={
              "À partir d’un modèle, exposez le cas de votre patient à Thiana et elle rédigera votre compte-rendu."
            }
          >
            <Flex gap={"small"}>
              <UserOutlined
                style={{
                  fontSize: !isSwitchChecked() ? 16 : 12,
                  transition: "font-size 0.5s ease",
                  color: !isSwitchChecked() ? token.colorPrimary : "#d9d9d9",
                }}
              />
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: 12,

                  color: !isSwitchChecked() ? token.colorPrimary : "#d9d9d9",
                }}
              >
                COMPTE-RENDU
              </Typography>
            </Flex>
          </Tooltip>
          <Flex
            align="center"
            className={
              isSwitchChecked()
                ? "customSwitchChecked"
                : "customSwitchUnchecked"
            }
          >
            <Switch
              size="small"
              checked={isSwitchChecked()}
              onChange={(checked) => onChangeMode(checked)}
              data-cy="action-switch"
            />
          </Flex>
          <Tooltip
            title={
              "Lancez la transcription pendant que vous échangez avec votre patient et Thiana rédigera pour vous une observation médicale structurée."
            }
          >
            <Flex gap={"small"}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: 12,
                  color: isSwitchChecked() ? token.colorPrimary : "#d9d9d9",
                }}
              >
                CONVERSATION
              </Typography>
              <TeamOutlined
                style={{
                  fontSize: isSwitchChecked() ? 16 : 12,
                  transition: "font-size 0.5s ease",
                  color: isSwitchChecked() ? token.colorPrimary : "#d9d9d9",
                }}
              />
            </Flex>
          </Tooltip>
        </Flex>
      </Flex>

      {currentScreenSize > breakpoints.md ? (
        <Flex vertical style={{ flex: 1 }} align="end" gap={2}>
          <Tag
            color="orange"
            style={{
              margin: 0,
              fontSize: 8,
              paddingLeft: 4,
              paddingRight: 4,
              paddingTop: 2,
              paddingBottom: 2,
              lineHeight: 1,
              top: 50,
              position: "fixed",
              right: 0,
            }}
          >
            version bêta
          </Tag>
          <img
            alt="thiana_logo_assistant"
            style={{
              width: 120,
              height: "auto",
            }}
            src={LOGO}
          />
        </Flex>
      ) : null}
    </Flex>
  );
}
