import { createContext } from "react";
import { initialState, Action, State } from "../reducer/flowReducer";
import {
  Professional,
  Report as ThianaReport,
  Subscription,
  User,
} from "@thiana/api-thiana-client";
import { ThianaAssistantMode } from "../types/Reports";
import { ThianaAssistantTheme } from "../types/Theme";

// Define context type
type Context = {
  user?: User;
  updateUser: (arg?: User) => void;

  professional?: Professional;
  updateProfessional: (arg?: Professional) => void;

  subscription?: Subscription;
  updateSubscription: (arg?: Subscription) => void;

  fileAvatar?: string;
  updateFileAvatar: (arg: string) => void;

  currentReport?: ThianaReport;
  updateCurrentReport: (arg: ThianaReport) => void;

  currentTranscription?: string;
  updateCurrentTranscription: (arg: string) => void;

  currentGeneration: string;
  updateCurrentGeneration: (arg: string) => void;

  isASRProcessing: boolean;
  updateIsASRProcessing: (arg: boolean) => void;

  isLLMProcessing: boolean;
  updateIsLLMProcessing: (arg: boolean) => void;

  extractionStep: string;
  updateExtractionStep: (arg: string) => void;

  isMenuHidden: boolean | undefined;
  updateIsMenuHidden: (arg: boolean) => void;

  currentTab: string;
  updateCurrentTab: (arg: string) => void;

  flowState: State;
  dispatchFlow: (arg: Action) => void;

  isSaved: SaveReport;
  updateIsSaved: (arg: SaveReport) => void;

  mode?: ThianaAssistantMode;
  updateMode: (arg: ThianaAssistantMode) => void;

  isMicrophoneAvailable: boolean;
  updateIsMicrophoneAvailable: (arg: boolean) => void;

  stepsRef: React.MutableRefObject<null>[];
  updateStepsRef: (arg: React.MutableRefObject<null>[]) => void;

  openTour: boolean;
  updateOpenTour: (arg: boolean) => void;

  theme: ThianaAssistantTheme;
  updateTheme: (arg: ThianaAssistantTheme) => void;
};

export type SaveReport = {
  isSaved: boolean;
  updatedField?: string;
};

// Context
export default createContext<Context>({
  user: undefined,
  updateUser: (arg?: User) => {},

  professional: undefined,
  updateProfessional: (arg?: Professional) => {},

  currentReport: undefined,
  updateCurrentReport: (arg: ThianaReport) => {},

  subscription: undefined,
  updateSubscription: (arg?: Subscription) => {},

  fileAvatar: undefined,
  updateFileAvatar: (arg: string) => {},

  currentTranscription: undefined,
  updateCurrentTranscription: (arg: string) => {},

  currentGeneration: "",
  updateCurrentGeneration: (arg: string) => {},

  isASRProcessing: false,
  updateIsASRProcessing: (arg: boolean) => {},

  isLLMProcessing: false,
  updateIsLLMProcessing: (arg: boolean) => {},

  extractionStep: "",
  updateExtractionStep: (arg: string) => {},

  isMenuHidden: true,
  updateIsMenuHidden: (arg: boolean) => {},

  currentTab: "",
  updateCurrentTab: (arg: string) => {},

  flowState: initialState,
  dispatchFlow: (arg: Action) => {},

  isSaved: { isSaved: true },
  updateIsSaved: (arg: SaveReport) => {},

  mode: "statement",
  updateMode: (arg: ThianaAssistantMode) => {},

  isMicrophoneAvailable: false,
  updateIsMicrophoneAvailable: (arg: boolean) => {},

  stepsRef: [],
  updateStepsRef: (arg: React.MutableRefObject<null>[]) => {},

  openTour: false,
  updateOpenTour: (arg: boolean) => {},

  theme: {
    colorStatement: "",
    colorConversation: "",
    sideBackgroundColor: "",
    contentBackgroundColor: "",
    label: "light",
    iconColor: "black",
  },
  updateTheme: (arg: ThianaAssistantTheme) => {},
});
