import { useContext, useEffect, useState } from "react";
import Context from "../../../context/Context";
import SideMenu from "../menu/SideMenu";
import { Report as ThianaReport } from "@thiana/api-thiana-client";
import { WarningFilled } from "@ant-design/icons";
import { Modal } from "antd";
import { isChrome, isSafari, isFirefox } from "react-device-detect";
import { DefaultEventsMap } from "socket.io/dist/typed-events";
import { Socket } from "socket.io-client";
import { Template } from "../../../types/Templates";
import { ThianaPagination } from "../../../types/Pagination";
import useReports from "../../../hooks/useReports";

interface Props {
  children: JSX.Element;
  reports: ThianaReport[];
  setReports: React.Dispatch<React.SetStateAction<ThianaReport[]>>;
  SOCKET_ASR: React.MutableRefObject<
    Socket<DefaultEventsMap, DefaultEventsMap> | undefined
  >;
  templates: Template[];
  setTemplates: React.Dispatch<React.SetStateAction<Template[]>>;
}

export default function Layout(props: Props) {
  const { isMenuHidden, updateIsMenuHidden } = useContext(Context);
  const [paginationReports, setPaginationReports] = useState<ThianaPagination>({
    items: 40,
    currentPage: 1,
    nbPages: 1,
  });
  // useContext
  const { theme } = useContext(Context);
  // useReports
  let { fetchReports } = useReports({
    reports: props.reports,
    setReports: props.setReports,
    paginationReports: paginationReports,
    setPaginationReports: setPaginationReports,
  });

  // Ce useEffect récupère l'état du menu dans le navigateur
  useEffect(() => {
    let res = localStorage.getItem("isMenuHidden");
    if (res === "false") updateIsMenuHidden(false);
    else if (res === "true") updateIsMenuHidden(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Ce useEffect permet de garder l'état du menu dans le navigateur
  useEffect(() => {
    localStorage.setItem("isMenuHidden", JSON.stringify(isMenuHidden));
  }, [isMenuHidden]);

  useEffect(() => {
    fetchReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationReports.currentPage]);

  const ModalBrowser = () => (
    <Modal
      onCancel={() => {}}
      onOk={() => {}}
      footer={<></>}
      closable={false}
      //@ts-ignore
      open={!isFirefox && !isChrome && !isSafari && !navigator.brave} // navigator.brave existe uniquement sur Brave, sinon undefined
      title={
        <div>
          <WarningFilled style={{ color: "orange", marginRight: 5 }} />
          Thiana Assistant n'est pas compatible avec ce navigateur.
        </div>
      }
    >
      Pour le moment, Thiana Assistant est disponible sur <b>
        Google Chrome
      </b>, <b>Safari</b>, <b>Firefox</b>, ou <b>Brave</b> uniquement. Veuillez
      changer de navigateur pour utiliser l'application dans les meilleures
      conditions.
    </Modal>
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: isMenuHidden ? 0 : 16,
        minHeight: "100vh",
        backgroundColor: theme.contentBackgroundColor,
      }}
    >
      <ModalBrowser />
      <div
        className="menu-transition"
        style={{ width: !isMenuHidden ? 320 : 0 }}
      >
        {!isMenuHidden ? (
          <SideMenu
            paginationReports={paginationReports}
            setPaginationReports={setPaginationReports}
            SOCKET_ASR={props.SOCKET_ASR}
            reports={props.reports}
            setReports={props.setReports}
            templates={props.templates}
            setTemplates={props.setTemplates}
          />
        ) : null}
      </div>

      <div
        style={{
          minHeight: "calc(100vh-20px)",
          flex: 1,
          paddingTop: 10,
          paddingRight: 10,
          paddingLeft: isMenuHidden ? 10 : 0,
          backgroundColor: theme.contentBackgroundColor,
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
