import jwt_decode from "jwt-decode";
import { AccessToken } from "../types/Token";
const hasValidToken = () => {
  let encodedToken: any = localStorage.getItem("accessJWT");
  if (encodedToken === null) return false;
  let token: AccessToken = jwt_decode(encodedToken);
  if (Date.now() >= token.exp * 1000) {
    window.location.replace("/logout");
    return false;
  }
  return true;
};
const isIAAllowed = (status?: string) => {
  switch (status) {
    case "canceled":
      return false;
    case "trialing":
      return true;
    case "active":
      return true;
    default:
      return false;
  }
};

export { hasValidToken, isIAAllowed };
